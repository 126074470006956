import type {Users} from "./type";
import {fetchData} from "../../utils/fetch";

export const getAllUsers = async (): Promise<{ rows: Users[], totalRecords: number }> => {
    try {
        const response = await fetchData<{ rows: Users[], totalRecords: number }>(`/v1/users`, {
            params: {}
        });
        if (response?.data) {
            return {
                rows: Array.isArray(response.data) ? response.data : [],
                totalRecords: response.data.totalRecords ?? 0
            }
        }

        throw response;
    } catch (err) {
        return { rows: [], totalRecords: 0 }
    }
}