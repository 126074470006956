export const formatDate = (date: string) => {
    return date ? new Intl.DateTimeFormat('en', {
        dateStyle: 'full',
    }).format(new Date(date)) : '';
}

export const formatArray = (arr: string[]) => {
    return arr && arr.length ? arr.join(', ') : '';
}

export const formatDateyyyyMMdd = (date: string) => {
    if (!date) return '';

    const todayDate = new Date(date); 
    const formatDate = todayDate.getDate() < 10 ? `0${todayDate.getDate()}`:todayDate.getDate();
    const formatMonth = todayDate.getMonth() < 10 ? `0${todayDate.getMonth()}`: todayDate.getMonth();
    const formattedDate = [todayDate.getFullYear(), formatMonth, formatDate].join('-');

    return formattedDate;
}

export const formatAmount = (amount: number) => {
    const formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
    });
    return formatter.format(amount);
}

export const convertToISOFormat = (dateString: string): string => {
    const [date, time] = dateString.split(' ');
    const isoString = `${date}T${time}:00.000Z`;
    return isoString;
}