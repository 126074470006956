import {fetchData} from "utils/fetch"
import {ApiResponse} from "./type";

type GetDivisionsParams = {
    perpage?: number | undefined;
    page?: number | undefined;
}


export const getDivisions = async (params: GetDivisionsParams): Promise<ApiResponse> => {
    try {
        const response = await fetchData<ApiResponse>(`/v1/divisions`);
        console.log(response);
        if (response?.data?.rows?.length) {
            return response?.data;
        }

        throw response;
    } catch (err) {
        return {
            totalRecords: 0,
            rows: []
        };
    }
}
