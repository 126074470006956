import { fetchData } from "utils/fetch"
import { DefaultTableProperties } from "constants/table";
import type { Churches, Division, Event, EventDetail, EventStatus, Ministry, MinistryOptions, MinistryType } from "./type";
import type { MinistrySetup, MinistrySetupDetail } from '../ministry-setup/type';

type GetEventParams = {
    division: string;
    churches?: string;
    search?: string;
    page?: number;
}

export const getRefChurches = async (params: { churches: string }): Promise<Churches[]> => {
    try {
        //const response = await fetchData<Churches[]>(`/v1/church/${params.churches}`);
        const response = await fetchData<Churches[]>(`/v1/churches`);
        if (response?.data?.length) {
            return response.data;
        }

        throw response;
    } catch (err) {
        return [];
    }
}

// export const getRefMinistry = async (params: { churches: string }): Promise<Ministry[]> => {
//     try {
//         const response = await fetchData<Ministry[]>(`/v1/ministry-type/${params.churches}`);
//         console.log(response)
//         if (response?.data?.length) {
//             return response.data;
//         }
//
//         throw response;
//     } catch (err) {
//         return [];
//     }
// }

export const getRefMinistry = async (params: { churches: string }): Promise<Ministry[]> => {
    try {
        const response = await fetchData<Ministry[]>(`/v1/ministries`);
        console.log(response)
        if (response?.data?.length) {
            return response.data;
        }

        throw response;
    } catch (err) {
        return [];
    }
}

export const getRefMinistryOptions = async (params: { churches: string }): Promise<MinistryOptions[]> => {
    try {
        const data = await getRefMinistry(params);
        if (data?.length) {
            return data.reduce<MinistryOptions[]>((items, item) => {
                console.log(item)
                const existing = items.find(ministry => ministry.id === item?.divisions?.ministries?.id);
                if (!item?.divisions?.ministries?.id) {
                    return items;
                }

                const { ministries, ministryId, ministryTypeId, ...division } = item.divisions;
                if (existing) {
                    if (existing?.divisions) {
                        existing.divisions.push(division);
                    } else {
                        existing.divisions = [division];
                    }
                } else {
                    items.push({
                        ...item.divisions.ministries,
                        divisions: []
                    });
                }
                
                return items;
            }, [])
        }

        throw data;
    } catch (err) {
        return [];
    }
}

export const getRefMinistryType = async (): Promise<MinistryType[]> => {
    try {
        const response = await fetchData<MinistryType[]>(`/v1/ministry-type`);
        if (response?.data?.length) {
            return response.data;
        }

        throw response;
    } catch (err) {
        return [];
    }
}

export const getRefDivision = async (): Promise<Division[]> => {
    try {
        const response = await fetchData<Division[]>(`/divisions`, {
            params: {
                filter: JSON.stringify({
                    include: [
                        { relation: 'ministries', scope: { order: ['name asc'] } },
                        { relation: 'ministryType' }
                    ]
                })
            }
        });

        if (response?.data?.length) {
            return response.data;
        }

        throw response;
    } catch (err) {
        return [];
    }
}

export const getEvents = async (params: GetEventParams): Promise<{ rows: Event[], totalRecords: number }> => {
    try {
        const response = await fetchData<{ rows: Event[], totalRecords: number }>(`/servolution/events/${params.division}/${params.churches}`, {
            params: {
                page: params?.page ?? 1,
                perpage: DefaultTableProperties.paginationPerPage,
                search: params?.search ?? ''
            }
        });
        if (response?.data?.rows) {
            return {
                rows: Array.isArray(response.data.rows) ? response.data.rows : [],
                totalRecords: response.data.totalRecords ?? 0
            }
        }

        throw response;
    } catch (err) {
        return { rows: [], totalRecords: 0 }
    }
}

export const getMinistrySetup = async (params: GetEventParams & { eventId: string }): Promise<{ rows: MinistrySetup[], totalRecords: number }> => {
    try {
        const response = await fetchData<{ rows: MinistrySetup[], totalRecords: number }>(`/servolution/ministry/setup/${params.eventId}/${params.division}`, {
            params: {
                page: params?.page ?? 1,
                perpage: DefaultTableProperties.paginationPerPage,
                search: params?.search ?? ''
            }
        });
        if (response?.data?.rows) {
            return {
                rows: Array.isArray(response.data.rows) ? response.data.rows : [],
                totalRecords: response.data.totalRecords ?? 0
            }
        }

        throw response;
    } catch (err) {
        return { rows: [], totalRecords: 0 }
    }
}

export const getMinistrySetupById = async (params: { eventId: string, divisionId: string }): Promise<MinistrySetupDetail[] | null> => {
    try {
        const response = await fetchData<MinistrySetupDetail[]>(`/servolution/ministry/setup/details/${params.eventId}/${params.divisionId}`);
        if (response?.data?.length) {
            return response.data;
        }
        throw response;
    } catch (err) {
        return [];
    }
}

export const getEventById = async (eventId: string): Promise<EventDetail | null> => {
    try {
        const response = await fetchData<EventDetail>(`/servolution/events/${eventId}`);
        if (response?.data) {
            return response.data;
        }

        throw response;
    } catch (err) {
        return null;
    }
}

export const postEvent = async (data: any): Promise<any> => {
    try {
        const response = await fetchData<any>(`/servolution/events`, {
            method: 'POST',
            data
        });
        if (response?.data) {
            return response;
        }
        throw response;
    } catch (err) {
        throw err;
    }
}

export const postEventDuplicate = async (eventId: string): Promise<any> => {
    try {
        const response = await fetchData<any>(`/servolution/events/${eventId}/duplicate`, {
            method: 'POST'
        });
        if (response?.data) {
            return response;
        }

        throw response;
    } catch (err: any) {
        throw err;
    }
}

export const patchEvent = async (eventId: string, data: any): Promise<any> => {
    try {
        const response = await fetchData<any>(`/servolution/events/${eventId}`, {
            method: 'PATCH',
            data
        });
        if (response?.data) {
            return response;
        }
        throw response;
    } catch (err) {
        throw err;
    }
}

export const patchEventStatus = async (eventId: string, status: EventStatus): Promise<any> => {
    try {
        const response = await fetchData<any>(`/servolution/events/${eventId}`, {
            method: 'PATCH',
            data: { status }
        });
        if (response?.data) {
            return response;
        }

        throw response;
    } catch (err: any) {
        throw err;
    }
}