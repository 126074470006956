import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'components/atoms/Table';
import {postEventDuplicate} from 'datas/event/client';
import {DefaultTableProperties} from 'constants/table';
import {Row as DivisionType} from 'datas/divisions/type';
import {useEvent} from 'datas/event/store';
import {showToast} from 'utils/toast';
import type {TableColumn} from 'react-data-table-component';
import './style.scss';
import {getDivisions} from "../../../datas/divisions/client";

type DataEventTable = DivisionType;

export const Divisions = () => {
    const typingDelay = 800;

    const navigate = useNavigate();
    const isLoading = useEvent(state => state.isLoading);
    const setIsLoading = useEvent(state => state.setIsLoading);
    const messageLoading = useEvent(state => state.messageLoading);
    const setMessageLoading = useEvent(state => state.setMessageLoading);

    const [isLoadingFilter, setIsLoadingFilter] = useState(false);
    const [filterKeyword, setFilterKeyword] = useState('');
    const [filterPage, setFilterPage] = useState<number>();
    const [dataEvent, setDataEvent] = useState<DataEventTable[]>([]);
    const [totalTableRows, setTotalTableRows] = useState(0);

    const onCreateEvent = () => {
        navigate('/events/create');
    }

    // const onPatchEventStatus = async (eventId: string, isAssessmentBroadcasted: boolean) => {
    //     try {
    //         setIsLoading(true);
    //         setMessageLoading('Patching Event Status ...');
    //         await patchEventStatus(
    //             eventId,
    //             isAssessmentBroadcasted ? EventStatus.CLOSE : EventStatus.OPEN
    //         );
    //         await onLoadDataEvent({ page: filterPage, perpage: 1 });
    //         showToast('success', 'Successfully patched');
    //     } catch (err: any) {
    //         const message = err?.error?.message;
    //         showToast('error', message ?? 'Something wrong');
    //     } finally {
    //         setIsLoading(false);
    //         setMessageLoading('');
    //     }
    // }

    const onDuplicateEvent = async (eventId: string) => {
        try {
            setIsLoading(true);
            setMessageLoading('Duplicating Event');
            await postEventDuplicate(eventId);
            await onLoadDataEvent({page: filterPage, perpage: 1 });
            showToast('success', 'Successfully duplicated');
        } catch (err: any) {
            const message = err?.error?.message;
            showToast('error', message ?? 'Something wrong');
        } finally {
            setIsLoading(false);
            setMessageLoading('');
        }
    }

    const onFilterKeyword = () => {
        onLoadDataEvent({ page: filterPage, perpage: 1  });
    }

    const onChangeFilterKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterKeyword(e.target.value);
    }

    const onChangeTablePage = (page: number) => {
        setFilterPage(page);
        onLoadDataEvent({ page: filterPage, perpage: 1 });
    }

    const columns = useMemo<TableColumn<DataEventTable>[]>(() => ([
        { id: 'name', name: 'Name', wrap: true, cell: row => row.name },
        { id: 'ministries', name: 'Ministry', wrap: true, cell: row => row.ministries.name },
        { id: 'ministry_type', name: 'Ministry Type', wrap: true, cell: row => row.ministries.ministryType.name },
        { id: 'service_type', name: 'Service Type', wrap: true, cell: row => row.serviceType },
    ]), [])

    const onLoadDataEvent = async (params?: { perpage?: number, page?: number }) => {
        try {
            setIsLoadingFilter(true);
            const response = await getDivisions({
                ...params
            });
            console.log(response);
            if (response?.rows?.length) {
                const data = response.rows.map((event, index) => ({
                    ...event
                }));
                setDataEvent(data);
                setTotalTableRows(response.totalRecords);
            }
        } catch (err) {
            // noop
        } finally {
            setIsLoadingFilter(false);
        }
    }

    useEffect(() => {
        const typingTimeOut = setTimeout(onFilterKeyword, typingDelay);
        return () => clearTimeout(typingTimeOut);
    }, [filterKeyword])

    return (
        <div className='event_container'>
            <div className='event_content'>
                <div className='event_content_header'>
                    <div className='position-relative'>
                        <FormControl
                            placeholder='Search ...'
                            onChange={onChangeFilterKeyword}
                            disabled={isLoadingFilter}
                        />
                        {isLoadingFilter && (
                            <Spinner
                                animation='border'
                                size='sm'
                                style={{ right: '0.75rem', top: '0.75rem' }}
                                className='position-absolute'
                            />
                        )}
                    </div>
                    <Button title='Create New Event' className='d-flex align-items-center gap-2 text-light' onClick={onCreateEvent}>
                        <span className='material-symbols-outlined'>add</span> Create New
                    </Button>
                </div>
                <Table
                    pagination
                    paginationServer
                    data={dataEvent ?? []}
                    columns={columns}
                    paginationPerPage={DefaultTableProperties.paginationPerPage}
                    paginationTotalRows={totalTableRows}
                    paginationComponentOptions={{ noRowsPerPage: true }}
                    onChangePage={onChangeTablePage}
                />

                {isLoading && (
                    <div className='event_content_loading d-flex flex-column align-items-center justify-content-center gap-1'>
                        <Spinner />
                        <span>{messageLoading || 'Processing ...'}</span>
                    </div>
                )}
            </div>
        </div>
    )
}
