import axios, { AxiosResponse, RawAxiosRequestConfig } from 'axios';
import { useAuth } from 'datas/auth/store';

// const baseUrl = 'https://api-vm-v3.gms.church/api';
const baseUrl = 'https://api-vm-staging.gms.church/api';

export const fetchData = <T = never, R = AxiosResponse<T>>(
    url: string,
    config?: RawAxiosRequestConfig & { v1?: boolean }
): Promise<R> => {
    const { authCode } = useAuth.getState();

    //Bearer code
    const headers = {
        'Authorization': authCode ? `Bearer ${authCode}` : '',
        ...(config?.headers ?? {})
    };

    const axiosConfig = {
        ...config,
        url: `${baseUrl}${url}`,
        headers,
    };

    return axios(axiosConfig)
        .then((response) => {
            if (response) return response;
            throw response;
        })
        .catch((error) => {
            const { data } = error?.response || {};
        return data || error;
    });
};
