import React, { FormEvent, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { EventMinistryType, Ministry, TabKey } from 'datas/event/type';
import { ministryCategoryRef, ministryOptionTypeRef, selectOptions } from "constants/event";
import { useEvent } from 'datas/event/store';
import { getRefMinistry, getRefMinistryType } from 'datas/event/client';
import './style.scss';

type DataRefMinistry = selectOptions & {
    data: Ministry[]
};

export const FormMinistryTypes = () => {
    const { setActiveTab, dataSchedule, dataMinistryType, setDataMinistryType } = useEvent();
    const [validated, setValidated] = useState(false);
    const [dataRefMinistry, setDataRefMinistry] = useState<DataRefMinistry[]>();
    const [dataRefMinistryType, setDataRefMinistryType] = useState<selectOptions[]>();
    console.log(dataRefMinistry);
    console.log(dataRefMinistryType)

    const onPrevPage = () => {
        setActiveTab(TabKey.form);
    }

    const onNextPage = () => {
        setActiveTab(TabKey.ministry_options);
    }

    const onLoadDataRefMinistry = async () => {
        const data = await getRefMinistry({ churches: dataSchedule.churchId });
        if (data?.length) {
            const refMinistry = ministryCategoryRef.map(ref => ({
                ...ref,
                data: data
                    .filter(item => item.ministryTypeId === ref.value)
                    .sort((a,b) => (a.divisions.ministries.name > b.divisions.ministries.name) 
                        ? 1  : ((b.divisions.ministries.name > a.divisions.ministries.name) 
                            ? -1  : 0
                        )
                )
            }));
            setDataRefMinistry(refMinistry);
        }
    }

    const onLoadDataRefMinistryType = async () => {
        const data = await getRefMinistryType();
        if (data?.length) {
            const refMinistryType = data.map(({ id, name }) => ({
                value: id,
                label: name,
            }));
            setDataRefMinistryType(refMinistryType);
        }
    }

    const onChange = (key: keyof EventMinistryType, value: string) => {
        setDataMinistryType(prev => ({
            ...prev,
            [key]: parseInt(value)
        }))
    }

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        try {
            const form = e.currentTarget;
            if (form.checkValidity() === false) {
                setValidated(true);
                return;
            }

            onNextPage();
        } catch (err: any) {

        } finally {
            // setValidated(false);
        }
    }

    useEffect(() => {
        onLoadDataRefMinistry();
        onLoadDataRefMinistryType();
    }, [])

    return (
        <Form
            noValidate
            validated={validated}
            className='form_event_ministry_type'
            onSubmit={onSubmit}
        >
            <div className='form_event_ministry_type_header'>
                <h2>Ministry Types</h2>
            </div>
            <div className='form_event_ministry_type_content'>
                <i className='d-block text-muted'>
                    * Determine the ministry and division type.
                </i>

                {dataRefMinistry && dataRefMinistryType ? (
                    <>
                        <Form.Group className='form_group mt-3'>
                            <Form.Label>Servolution Type</Form.Label>
                            <Form.Select
                                required
                                aria-label="Servolution Type"
                                onChange={(e) => onChange('servolutionTypeId', e.target.value)}
                                value={dataMinistryType.servolutionTypeId}
                            >
                                <option hidden value=''>Pilih kategori</option>
                                {dataRefMinistryType.map(option => (
                                    <option value={option.value} key={`option_${option.value}`}>{option.label}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type='invalid'>
                                Pertanyaan wajib diisi
                            </Form.Control.Feedback>
                        </Form.Group>

                        {dataMinistryType.servolutionTypeId === 1 && (
                            <Form.Group className='form_group mt-3'>
                                <Form.Label>Choose the type of first and second option</Form.Label>
                                <Form.Select
                                    required
                                    aria-label="Choose the type of first and second option?"
                                    onChange={(e) => onChange('firstOptionSecondOptionType', e.target.value)}
                                    value={dataMinistryType.firstOptionSecondOptionType}
                                >
                                    <option hidden value=''>Pilih kategori</option>
                                    {ministryOptionTypeRef.map(option => (
                                        <option value={option.value} key={`option_${option.value}`}>{option.label}</option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type='invalid'>
                                    Pertanyaan wajib diisi
                                </Form.Control.Feedback>
                            </Form.Group>
                        )}

                        <hr/>

                        <Row className='ministry_type_container'>
                            {dataRefMinistry?.map((ref) => (
                                <Col key={`ref-ministry-${ref.value}`}>
                                    <h4 className='mb-2 fw-bold text-primary'>{ref.label}</h4>
                                    {ref.data?.map(item => (
                                        <div className='ministry_type_card py-2' key={`ref-ministry-item-${item.divisionsId}`}>
                                            <span>{item?.divisions?.ministries?.name}</span>
                                            <span className='text-muted'>{item?.divisions.name}</span>
                                        </div>
                                    ))}
                                </Col>
                            ))}
                        </Row>

                        <div className='w-100 d-flex justify-content-end gap-1 mt-3'>
                            <Button variant='secondary' onClick={onPrevPage}>Previous</Button>
                            <Button type='submit' className='d-flex align-items-center gap-1 text-light'>
                                Next <span className='material-symbols-outlined'>keyboard_arrow_right</span>
                            </Button>
                        </div>
                    </>
                ) : null}
            </div>
        </Form>
    )
}
