import Event from "components/pages/Event";
import EventEdit from "components/pages/EventEdit";
import EventMinistrySetup from "components/pages/EventMinistrySetup";
import EventMinistrySetupEdit from "components/pages/EventMinistrySetupEdit";
import Baptism from "./components/pages/Baptism";
import Divisions from "./components/pages/Divisions";
import User from "./components/pages/User";

export type Route = {
    id: string;
    pathname: string;
    icon: string;
    label: string;
    category: string;
    exact?: boolean;
}

export const componentLists = {
    'events': Event,
    'events-create': EventEdit,
    'events-edit': EventEdit,
    'events-setup': EventMinistrySetup,
    'events-setup-edit': EventMinistrySetupEdit,
    'Baptism':Baptism,
    'users':User,
    'divisions': Divisions
}

export const routeLists: Route[] = [
    { id: 'users', pathname: '/users', icon: 'person', label: 'Users', category: 'Users & Roles', exact: true },
    { id: 'roles', pathname: '/roles', icon: 'lock', label: 'Roles', category: 'Users & Roles', exact: true },
    { id: 'ministries', pathname: '/ministries', icon: 'format_list_bulleted', label: 'Ministries', category: 'Servolution', exact: true },
    { id: 'divisions', pathname: '/divisions', icon: 'format_list_bulleted', label: 'Divisions', category: 'Servolution', exact: true },
    { id: 'events', pathname: '/events', icon: 'date_range', label: 'Events', category: 'Servolution', exact: true },
    { id: 'events-setup', pathname: '/events/setup', icon: 'date_range', label: 'Ministry Setup', category: 'Servolution', exact: true },
    { id: 'events-setup-edit', pathname: '/events/setup/edit', icon: 'date_range', label: 'Edit', category: 'Servolution', exact: true },
    { id: 'events-create', pathname: '/events/create', icon: 'date_range', label: 'Create', category: 'Servolution', exact: true },
    { id: 'events-edit', pathname: '/events/edit', icon: 'date_range', label: 'Edit', category: 'Servolution', exact: true },
    { id: 'Baptism', pathname: '/Baptism', icon: 'person', label: 'Baptism', category: 'Servolution', exact: true },
    { id: 'volunteer-lists', pathname: '/volunteer-lists', icon: 'format_list_bulleted', label: 'Lists', category: 'Volunteer', exact: true }
]

export default routeLists;